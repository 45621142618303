import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout'
import PageHeading from '../components/pageheading';
import Employees from '../components/employees';
import Maps from '../components/map';

// markup
const AboutPage = () => {
  return (
    <Layout>
        <Helmet>
            <title>Delfi.se - Om oss</title>
        </Helmet>
        <PageHeading heading="Om oss" preamble="Du hittar oss på Drottningatan 61 i Stockholm och når oss på <a href='mailto:info@delfi.se'>info@delfi.se</a>." />
        <Employees />
        <Maps />
    </Layout>
  )
}

export default AboutPage;
