import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const PageHeading = props => {

    return (
        <header className={classNames({
            'page-heading': true,
            ...props.modifiers
        })}>
            <div className="page-heading__col">
                <h1 className="page-heading__heading">{props.heading}</h1>
                <p className="page-heading__preamble" dangerouslySetInnerHTML={{__html: props.preamble}}></p>
            </div>
            {props.img &&
                <div className="page-heading__col">
                    <img src={props.img} alt="" />
                </div>
            }
        </header>
    );
};

export default PageHeading;