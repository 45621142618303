import React from 'react';
import anders from '../../images/persons/anders.jpg';
import joakim from '../../images/persons/joakim.jpg';
import ann from '../../images/persons/ann.jpg';
import annaLindberg from '../../images/persons/anna_lindberg.jpg';
import annLind from '../../images/persons/anna_lind.jpg';
import annaLindstrom from '../../images/persons/anna_lindstrom.jpg';
import erik from '../../images/persons/erik.jpg';
import './styles.scss';

const Employees = props => {
    return (
        <div className="employees">
            <div className="employees__employee">
                <div className="employees__img">
                    <div className="employees__img-container">
                        <img src={anders} alt="" />
                    </div>
                </div>
                <h2 className="employees__heading">Anders Strandsten</h2>
                <h3 className="employees__subheading">VD</h3>
                <ul className="employees__contact-info">
                    <li><a href="mailto:anders.strandsten@delfi.se">anders.strandsten@delfi.se</a></li>
                    <li>08-522 243 09</li>
                    <li>070-771 60 45</li>
                </ul>
            </div>

            <div className="employees__employee">
                <div className="employees__img">
                    <div className="employees__img-container">
                        <img src={joakim} alt="" />
                    </div>
                </div>
                <h2 className="employees__heading">Joakim Browall</h2>
                <h3 className="employees__subheading">Vice VD</h3>
                <ul className="employees__contact-info">
                    <li><a href="mailto:anders.strandsten@delfi.se">joakim.browall@delfi.se</a></li>
                    <li>08-522 243 08</li>
                    <li>070-491 77 11</li>
                </ul>
            </div>

            <div className="employees__employee">
                <div className="employees__img">
                    <div className="employees__img-container">
                        <img src={ann} alt="" />
                    </div>
                </div>
                <h2 className="employees__heading">Ann Tindberg</h2>
                <h3 className="employees__subheading">Register, Säljdistrikt</h3>
                <ul className="employees__contact-info">
                    <li><a href="mailto:ann.tindberg@delfi.se">ann.tindberg@delfi.se</a></li>
                    <li>08-522 243 03</li>
                </ul>
            </div>

            <div className="employees__employee">
                <div className="employees__img">
                    <div className="employees__img-container">
                        <img src={annaLindberg} alt="" />
                    </div>
                </div>
                <h2 className="employees__heading">Anna Lindberg</h2>
                <h3 className="employees__subheading">Statistik</h3>
                <ul className="employees__contact-info">
                    <li><a href="mailto:anna.lindberg@delfi.se">anna.lindberg@delfi.se</a></li>
                    <li>08-522 243 09</li>
                    <li>072-211 45 55</li>
                </ul>
            </div>


            <div className="employees__employee">
                <div className="employees__img">
                    <div className="employees__img-container">
                        <img src={annLind} alt="" />
                    </div>
                </div>
                <h2 className="employees__heading">Anna Lind</h2>
                <h3 className="employees__subheading">Statistik</h3>
                <ul className="employees__contact-info">
                    <li><a href="mailto:anna.lind@delfi.se">anna.lind@delfi.se</a></li>
                    <li>08-522 243 02</li>
                </ul>
            </div>


            <div className="employees__employee">
                <div className="employees__img">
                    <div className="employees__img-container">
                        <img src={annaLindstrom} alt="" />
                    </div>
                </div>
                <h2 className="employees__heading">Anna Lindström</h2>
                <h3 className="employees__subheading">Dabas</h3>
                <ul className="employees__contact-info">
                    <li><a href="mailto:anna.lindstrom@delfi.se">anna.lindstrom@delfi.se</a></li>
                    <li>08-522 243 01</li>
                </ul>
            </div>

            <div className="employees__employee">
                <div className="employees__img">
                    <div className="employees__img-container">
                        <img src={erik} alt="" />
                    </div>
                </div>
                <h2 className="employees__heading">Erik Lindberg</h2>
                <h3 className="employees__subheading">Produktutveckling</h3>
                <ul className="employees__contact-info">
                    <li><a href="mailto:erik.lindberg@delfi.se">erik.lindberg@delfi.se</a></li>
                    <li>08-522 243 07</li>
                </ul>
            </div>

            <div className="employees__employee">
                <div className="employees__img">
                    <div className="employees__img-container">
                        {/*<img src={anders} alt="" />*/}
                    </div>
                </div>
                <h2 className="employees__heading">Anneli Alkevik</h2>
                <h3 className="employees__subheading">Register</h3>
                <ul className="employees__contact-info">
                    <li><a href="mailto:anneli.alkevik@delfi.se">anneli.alkevik@delfi.se</a></li>
                    <li>08-522 243 06</li>
                </ul>
            </div>
        </div>
    );
};

export default Employees;
