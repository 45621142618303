import React from 'react';
import GoogleMapReact from 'google-map-react';
import './styles.scss';

const defaultProps = {
    center: {
      lat: 59.3339553,
      lng: 18.0588537,
    },
    zoom: 15,
  }
  
const DelfiMarker = () => <div className="maps__marker"></div>
  
const Map = props => {
    return (
        <div className="maps">
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCcTFAfKseNTKLBNJI433cfQMETSR0LEa4' }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}>
                <DelfiMarker
                    lat={59.3339168}
                    lng={18.0610541} />
            </GoogleMapReact>
        </div>
    );
};

export default Map;